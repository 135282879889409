import * as React from 'react'
import {Props, useEffect, useRef, useState} from "react";
import Recaptcha from "react-recaptcha";
interface ComponentForm {
    asunto?: string
}

const ContactForm = ({asunto = 'Requiero más información'}: ComponentForm) => {
    let recaptchaInstance;
    const ComponentForm = useRef();
    const executeCaptcha = () => {
        recaptchaInstance.execute();
    }
    const verifyCallback = () => {
        ComponentForm.current && ComponentForm.current.submit();
    }

    useEffect(() => {

        const handleShow = () => {
            ComponentForm.current && ComponentForm.current.reset()
        }

        window.addEventListener('pageshow', handleShow)

        return () => window.removeEventListener('pageshow', handleShow);
    }, [])

    return (
        <form ref={ComponentForm} className={'footer'} action="https://getform.io/f/069413fd-b9da-4f06-a08f-0516838f0d43" method={'POST'}>
            <Recaptcha
                elementID={'ComponentForm'}
                ref={e => recaptchaInstance = e}
                sitekey="6LelaZQiAAAAANLAw-EwpD73EGatocoLeJdknTjx"
                size="invisible"
                verifyCallback={() => verifyCallback()}
            />
            <div className="contenedorflexfooter">
                <ul className="colizq">
                    <li className={'especial3'}>Acércate a nosotros</li>
                    <li className={'especial2'}>Déjanos tu información y pronto te contactaremos</li>

                    <li className={"inputs"}>
                        <input type="hidden" name={'subject'} value={asunto}/>
                        <input type="hidden" name={'message'} value={'Mensaje enviado desde Popup'}/>
                        <input type="text" required={true} name="name" placeholder={'Nombre'}/>
                        <input type={'email'} required={true} name="email" placeholder="Correo electrónico"/>
                        <input type="text" required={true} name="company" placeholder={'Empresa'}/>
                        <input type="text" required={true} name="position" placeholder={'Puesto'}/>
                        <input type="tel" required={true} name="phone" placeholder={'Teléfono'}/>
                        <button type={"button"} className="cyan small"
                                onClick={(event) => {
                                    event.preventDefault();
                                    ComponentForm.current.reportValidity() && executeCaptcha()
                                }}>
                            Enviar
                        </button>
                    </li>
                </ul>
            </div>
        </form>
    )
}
export default ContactForm