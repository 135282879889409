import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import {Link} from "gatsby";
import {useRef, useState} from "react";
import AnimatedNumber from "animated-number-react";
import {ParallaxBanner} from "react-scroll-parallax";
import ContactForm from "../components/contactForm";

const IndexPage = () => {

    const savings = [
        {
            printers: '10',
            savings: 20
        },
        {
            printers: '100',
            savings: 23
        },
        {
            printers: '+1000',
            savings: 28
        }
    ]

    const [monthSaving, setMonthSaving] = useState(savings[0])

    const ContactFormModalRef = useRef()

    const [modalFormVisible, setModalFormVisible] = useState(false)

    return (
        <Layout>

            <Carousel responsive={{all: {breakpoint: {max: 4000, min: 0}, items: 1}}} className={"cover-slider"}
                      showDots={true}
                      arrows={false}
                      autoPlay
                      infinite
                      pauseOnHover={false}
                      shouldResetAutoplay={true}
                      autoPlaySpeed={8000}>
                <div className={'item'}>
                    <div className={'sliderleft'}>
                        <h3>Servicios tecnológicos al alcance de <span>todos</span>.</h3>
                        <p className={'contenido1'}>Proporcionamos servicios de TI con alta calidad, que les permite a
                            la empresas lograr mayor eficiencia operativa, así como un mejor control y administración en
                            sus ambientes operativos.</p>
                        <div className="buttons">
                            <Link to={"/casos"} className="btn-push"><span>Ver Casos de éxito</span></Link>
                            <Link to={"/servicios"} className={'white'}><u>Ir a Servicios</u></Link>
                        </div>
                        <ul className={'wrapperabajo'}>
                            <li>
                                <h4>+40</h4>
                                <p className={'contenido1'}>años de experiencia</p>
                            </li>
                            <li>
                                <h4>+50</h4>
                                <p className={'contenido1'}>empresas con las que hemos colaborado</p>
                            </li>
                        </ul>

                    </div>
                    <div className={'sliderright'}>
                        <StaticImage className="img" src="../images/dibujo-slider-1.svg" alt="Incrementa la utilidad de tu negocio sin costo"/>
                    </div>
                </div>
                <div className={'item'}>
                    <div className={'sliderleft'}>
                        <h3>Transformación digital al alcance de <span>todos</span>.</h3>
                        <p className={'contenido1'}>Apoyamos a las empresas a transformar su infraestructura de
                            impresión en una plataforma que permite ver, evaluar y manejar información precisa.</p>
                        <div className="buttons">
                            <Link to={"/casos"} className="btn-push"><span>Ver Casos de éxito</span></Link>
                            <Link to={"/servicios"} className={'white'}><u>Ir a Servicios</u></Link>
                        </div>
                        <ul className={'wrapperabajo'}>
                            <li>
                                <h4>+40</h4>
                                <p className={'contenido1'}>años de experiencia</p>
                            </li>
                            <li>
                                <h4>+50</h4>
                                <p className={'contenido1'}>empresas con las que hemos colaborado</p>
                            </li>
                        </ul>

                    </div>
                    <div className={'sliderright'}>
                        <StaticImage className="img" src="../images/dibujo-slider-2.svg" alt="Incrementa la utilidad de tu negocio sin costo"/>
                    </div>

                </div>
            </Carousel>

            <div className={'wrapperbase home-texto-1'}>
                <div className={'HomeTexto1'}
                     data-sal="slide-left"
                     data-sal-duration={"600"}>
                    <h4>Los servicios y soluciones de T.I.O. Alemar facilitan la evolución hacia la transformación
                        digital
                    </h4>
                </div>
            </div>

            <div className={'wrapperbase wrapperhometext'}>
                <h1 className={'home-text'}
                    data-sal="slide-right"
                    data-sal-duration={"600"}
                >Te decimos como podemos ayudarte.</h1>
            </div>

            <div className={'wrapperbase home-texto-1'}>
                <div className={'HomeTexto1'}
                     data-sal="slide-left"
                     data-sal-duration={"600"}>
                    <h4>Simplifica procesos.
                    </h4>
                    <p className={'especial2'}>Gestiona documentos e información
                        de forma inteligente desde tu computadora o celular.</p>
                </div>
            </div>


            <div className={'home-ditalizacion'}>
                <div className={'DigitalizacionFlotando dfleft'}
                     data-sal="slide-right"
                     data-sal-duration={"600"}>
                    <h5>Digitalización y Automatización de <b>Procesos</b></h5>
                    <p className={'contenido1'}>Crea una conexión directa y sin esfuerzo entre tu organización y tus
                        documentos.</p>
                    <Link to={"/servicio-digitalizacion-procesos"}
                          className="btn-push-black"><span>Ver más</span></Link>
                </div>
                <div className="DigitalicacionBG" data-sal="slide-left"
                     data-sal-duration={"600"}>
                    <StaticImage className={'DibujoDigitalizacionLeft'} src="../images/dibujo-digitalizacion-left.png"
                                 alt="ALEMAR"/>
                    <StaticImage className={'DibujoDigitalizacionRight'} src="../images/dibujo-digitalizacion-right.png"
                                 alt="ALEMAR"/>
                </div>
            </div>


            {/*<div className={'logossocios'}>
                <ul>
                    <li data-sal="slide-up"
                        data-sal-delay="100">
                        <StaticImage className={"img"} src="../images/Lexmark.png" alt="Lexmark logo"/>
                    </li>
                    <li data-sal="slide-up"
                        data-sal-delay="200">
                        <StaticImage className={"img"} src="../images/aws_logo.png" alt="AWS Logo"/>
                    </li>
                    <li data-sal="slide-up"
                        data-sal-delay="300">
                        <StaticImage className={"img"} src="../images/Kodak_Alaris.png" alt="Kodak Logo"/>
                    </li>
                    <li data-sal="slide-up"
                        data-sal-delay="400">
                        <StaticImage className={"img"} src="../images/M-Files-Logo-.png" alt="M Files Logo"/>
                    </li>
                    <li data-sal="slide-up"
                        data-sal-delay="500">
                        <StaticImage className={"img"} src="../images/DOQSOFT.png" alt="DOQSOFT Logo"/>
                    </li>
                </ul>
            </div>*/}

            <ParallaxBanner
                layers={[{
                    speed: 30,
                    children: (
                        <StaticImage style={{width: '100%'}} src="../images/bg-home.jpg"
                                     alt="Toma el control de la rentabilidad de tu empresa con datos en tiempo real"/>
                    )
                }]}
                className="bg-parallax"
            />


            <div className={'wrapperbase home-texto-1'}>
                <div className={'HomeTexto1'}
                     data-sal="slide-left"
                     data-sal-duration={"600"}>
                    <h4>¡Toma el control!</h4>
                    <p className={'especial2'}>Administra la generación de documentos y evita abusos en la
                        impresión.</p>
                </div>
            </div>

            <div className="saving-calculator">
                <div className="left" data-sal="slide-up"
                     data-sal-duration={"600"}>
                    <h4>¿Cuánto puedes ahorrar?</h4>
                    <div className="percent">
                        <StaticImage className={'img'} src="../images/circle.png"
                                     alt="Ahorro ALEMAR"/>
                        <div className="content">
                            <p className="h2">
                                +<AnimatedNumber
                                formatValue={(value) => value.toFixed(0)}
                                value={monthSaving.savings}
                            />%
                            </p>
                            <p className={"contenido2"}>Ahorro por mes</p>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <p className='especial2'>¿Alguna ves te has preguntado cuánto dinero <b>puedes ahorrar</b> en la
                        generación de documentos?</p>
                    <hr/>
                    <p className='especial3 sub'>¿Con cuántos dispositivos de impresión cuenta Tu empresa?</p>
                    <div className="options">
                        {savings.map((option, index) => {
                            return (
                                <button
                                    className={`contenido1 ${monthSaving.printers == option.printers ? 'active' : ''}`}
                                    key={'opt-' + index} onClick={() => {
                                    setMonthSaving(savings[index])
                                }}>
                                    <span>{option.printers}</span>
                                </button>
                            )
                        })}
                    </div>
                    <hr/>
                    <ul>
                        <li className={'especial3'}>Podrías estar ahorrando el +20% de impresión al mes.</li>
                        <li className={'especial3'}>Ayudamos a la empresas a imprimir sólo lo necesario.</li>
                    </ul>
                    <p className={'especial3 bottom'}>¿Te gustaría saber cómo?</p>
                    <button onClick={() => setModalFormVisible(true)} type={"button"} className='btn-push-black'>
                        <span>Quiero conocer más</span>
                    </button>
                </div>
            </div>

            <ParallaxBanner
                layers={[{
                    speed: 30,
                    children: (
                        <StaticImage style={{width: '100%'}} src="../images/bg-home-2.png"
                                     alt="Descubre en donde tienes gastos operativos invisibles"/>
                    )
                }]}
                className="bg-parallax"
            />

            <div
                className={'wrapperbase home-how'}>  {/*Una clase en particular para cada seccion estaria bien, para que sepas donde estas*/}
                <div className={'HomeDibujoLeft'}>
                    <StaticImage className={'img'} src="../images/dibujolefthome.png" alt="ALEMAR"/>
                </div>
                <div className={'Home4valores'}>
                    <div className={'HomeCard'}
                         data-sal="fade"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/recursos.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Reduce Costos</p>
                        <p className={'contenido1'}>No somos solo una herramienta de reducción de costos. Ofrecemos
                            estrategias para transformar el flujo de información impresa y digital.</p>
                    </div>
                    <div className={'HomeCard'}
                         data-sal="fade"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/padlock1.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Protege tu negocio</p>
                        <p className={'contenido1'}>Evita dejar información confidencial abandonada en las bandejas de
                            impresión.</p>
                    </div>
                    <div className={'HomeCard'}
                         data-sal="fade"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/data-center1.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Ahorra tiempo</p>
                        <p className={'contenido1'}>Con nuestra metodología reduce costos y descubre los beneficios que
                            tenemos para ti.</p>
                    </div>
                    <div className={'HomeCard'}
                         data-sal="fade"
                         data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/wedding-location.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Toma el control</p>
                        <p className={'contenido1'}>Las empresas desconocen el costo de la generación de documentos y el
                            volumen de sus impresiones.</p>
                    </div>
                </div>
            </div>


            {modalFormVisible &&
                <div className={'modal form-contact'}>
                    <div className="bg-black content">
                        <button onClick={() => setModalFormVisible(false)}>x</button>
                        <ContactForm asunto={'Quiero conocer más sobre dispositivos de impresión'}/>
                    </div>
                </div>}

        </Layout>
    )
}
export const Head = () => {
    return (
        <>
            <title>Ahorra hoy con T.I.O. Alemar</title>
            <meta
                name="description" content="Con Tecnologías de la Información Oportuna, (T.I.O. Alemar) y nuestros servicios en automatización de procesos, digitalización e impresión empresarial su compañía podrá reducir gastos operativos y tener mayor control e información oportuna."/>
            <meta name="keywords" content="Documentos, TI, Digitalización, Impresiones, Ahorro"/>
        </>
    )
}

export default IndexPage
